<template>
  <!-- <content-title title="热招项目" /> -->
  <div class="hot-project" v-if="data.hotList.length > 0">
    <template v-for="(item, index) in data.hotList" :key="index">
      <div class="item" @click="jump(item)">
        <div class="describe">
          <h1>{{ item.title_en }}</h1>
          <p>{{ item.description_en }}</p>
        </div>
        <div class="time">
          <div class="top">
            <span>{{ dayjs(item.created_at).format('MM.DD') }}</span>
          </div>
          <div class="bottom">
            <span>{{ dayjs(item.created_at).format('YYYY') }}</span>
          </div>
        </div>
      </div>
    </template>
  </div>
  <el-empty description="暂无内容" v-else />
  <!-- 分页 -->
  <div class="paging" v-show="data.hotList.length > 0">
    <el-pagination
      background
      layout="pager"
      :page-size="data.limit"
      :total="data.total"
      @current-change="handlerChange"
    />
  </div>
</template>

<script setup>
  import { reactive, onMounted } from 'vue'
  import { apiNewsList } from '@/api/list.js'
  import useParams from '@/hooks/getParams.js'
  import { useRoute,useRouter } from 'vue-router'
  import dayjs from 'dayjs'
  // 获取url中的参数
  let params = useParams()
  let $route = useRoute()
  let $router = useRouter()

  const data = reactive({
    limit: 8,
    page: 1,
    total: 0,
    hotList: []
  })
  
  const handlerGteList = async () => {
    let { code, data: res } = await apiNewsList({
      page: data.page,
      limit: data.limit,
      type_id: params.detailsId,
      keywords: $route.query.q,
      is_en: 3
    })
    if (code === 200) {
      data.hotList = res.data
      data.total = res.total
    }
  }

  onMounted(() => {
    handlerGteList()
  })
  // 分页切换
  const handlerChange = (num) => {
    data.page = num
    handlerGteList()
  }

  const jump = ({id,type_id}) => {
    $router.push({
      name: 'detile',
      params:{
        detailsId:id,
        navId:type_id
      }
    })
  }
</script>

<style lang="scss" scoped>
  // 分页器样式
  // .paging {
  //   display: flex;
  //   align-items: center;
  //   justify-content: center;
  //   padding: 80px 0 160px 0px;
  //   &:deep(.el-pager) {
  //     .number,
  //     .more {
  //       width: 41px;
  //       height: 42px;
  //       background: #ffffff;
  //       border-radius: 4px 4px 4px 4px;
  //       opacity: 1;
  //       border: 1px solid #aaaaaa;
  //       &:hover {
  //         color: $hover-color;
  //       }
  //     }

  //     .is-active {
  //       width: 42px;
  //       height: 42px;
  //       background: $hover-color !important;
  //       border-radius: 4px 4px 4px 4px;
  //       opacity: 1;
  //       border: 1px solid $hover-color;
  //       color: #fff !important;
  //     }
  //   }
  // }

  @import '@/style/paging.scss';

  .hot-project {
    width: 100%;

    & div:last-child {
      margin-bottom: 0px;
    }
    .item {
      width: 100%;
      height: 1.7188rem;
      padding: .3281rem 0px .3281rem .4375rem;
      background-color: #eaeaea;
      display: flex;
      justify-content: space-between;
      align-items: center;
      transition: background-color 0.3s;
      cursor: pointer;
      margin-bottom: .25rem;

      .describe {
        width: 6.8906rem;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: flex-start;
        @include transition;
        h1 {
          width: 6.8906rem;
          font-size: .25rem;
          font-weight: bold;
          color: #333333;
          line-height: .3906rem;
          @include ellipsis(1);
        }

        p {
          width: 6.3984rem;
          height: .4531rem;
          font-size: .125rem;
          font-weight: 400;
          color: #7e7e7e;
          line-height: .25rem;
          @include ellipsis(2);
        }
      }

      .time {
        width: 1.0234rem;
        height: .7344rem;

        > div {
          width: 100%;
          height: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .top {
          background: #de3a00;
          font-size: .2813rem;
          font-weight: 400;
          color: #ffffff;
          line-height: .4453rem;
        }

        .bottom {
          background: #fff;
          font-size: .1484rem;
          font-weight: 400;
          color: #333333;
          line-height: .4297rem;
        }
      }

      &:hover {
        background: linear-gradient(267deg, #ffbda5 0%, #e34f1b 63%, #de3a00 100%);
        p,
        h1 {
          color: #fff;
        }
      }
    }
  }
</style>
